import axios from "axios";
import { HOST_URL } from "./config";
import Cookies from "js-cookie";
// import store from '../store';

axios.defaults.baseURL = HOST_URL;

// @ts-ignore
axios.defaults.headers['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
    const accessToken = Cookies.get('token')//store.getters['auth/accessTokenComputed'];

    if (accessToken) { // @ts-ignore
        config.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    if (!config.data) config.data = {};

    return config;
});
