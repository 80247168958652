export const AndroidsFrame4 = () => {
  const isMobile = window.innerWidth < 1030
  return (
    <>
      {isMobile && (
        <svg width="1108" height="216" viewBox={`0 0 ${isMobile ? 475 : 1108} 216`} fill="none" xmlns="http://www.w3.org/2000/svg">
          {!isMobile && ( <path fill-rule="evenodd" clip-rule="evenodd" d="M471 5H1103V119.124L1061.51 153H471V5ZM1063.29 158L1108 121.497V0H466V158H1063.29Z" fill="url(#paint0_linear_7177_20548)"/>)}
          <path fill-rule="evenodd" clip-rule="evenodd" d="M59.3737 0H471V158.036L413.036 216H0.5V66.5529L59.3737 0ZM61.6263 5L5.5 68.4471V211H410.964L466 155.964V5H61.6263Z" fill="url(#paint0_linear_5153_22706)"/>
          <defs>
            <linearGradient id="paint0_linear_5153_22706" x1="388" y1="-6.71526" x2="-1.36965" y2="182.162" gradientUnits="userSpaceOnUse">
              <stop stop-color="#DE00A0"/>
              <stop offset="1" stop-color="#B200DD"/>
            </linearGradient>
          </defs>
        </svg>
      )}
      {!isMobile && (
        <svg width="1108" height="217" viewBox="0 0 1108 217" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 69H639.5V214H45.5644L2.5 180.62V69Z" stroke="url(#paint0_linear_7181_20552)" stroke-width="5"/>
          <path d="M1049 214H640V68L697.5 3H1105.5V157.5L1049 214Z" stroke="url(#paint1_linear_7181_20552)" stroke-width="5"/>
          <defs>
            <linearGradient id="paint0_linear_7181_20552" x1="642" y1="60.4574" x2="222.466" y2="457.804" gradientUnits="userSpaceOnUse">
              <stop stop-color="#B200DD"/>
              <stop offset="1" stop-color="#DE00A0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_7181_20552" x1="1105.5" y1="-5.49996" x2="639.5" y2="222" gradientUnits="userSpaceOnUse">
              <stop stop-color="#DE00A0"/>
              <stop offset="1" stop-color="#B200DD"/>
            </linearGradient>
          </defs>
        </svg>
      )}
    </>


  )
}