import { FC } from "react";

import { useAppSelector } from "./redux/store";

import RoutesComponent from "./routes";
import { selectLoading } from "./redux/reducers/loading/reducer";
import Spinner from "./components/Spinner";

const App: FC = () => {
    const loadingIsActive = useAppSelector(selectLoading);

    return (
        <>
            <RoutesComponent/>

            { loadingIsActive && (
                <div className="spinner__wrapper">
                    <div className="spinner-block">
                        <Spinner />
                    </div>
                </div>
            ) }
        </>
    )
}
export default App;
