export const DocumentIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.4579 44.9999H11.4579C8.69649 44.9999 6.45793 42.7613 6.45794 39.9999L6.45814 9.99997C6.45815 7.23856 8.69672 5 11.4581 5H33.9587C36.7201 5 38.9587 7.23858 38.9587 10V23.75M28.9587 37.9167L33.5421 42.5L43.5421 32.4996M15.2087 15H30.2087M15.2087 22.5H30.2087M15.2087 30H22.7087" stroke="url(#paint0_linear_6597_22104)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <defs>
        <linearGradient id="paint0_linear_6597_22104" x1="48.5" y1="-1.22299e-05" x2="-16.5972" y2="2.7149" gradientUnits="userSpaceOnUse">
          <stop stop-color="#00FF01"/>
          <stop offset="1" stop-color="#FFF624"/>
        </linearGradient>
      </defs>
    </svg>

  )
}