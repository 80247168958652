import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { $createCall, $getQuestionList } from "../../api/requests/faq";
import { successNotify } from "../../notifications";

import TextField from "../../components/form/TextField";
import Button from "../../components/form/Button";
import FilterSelect from "../../components/FilterSelect";

import Arrow from "../../img/icon/Arrow";

export const Questions: FC = () => {
    const navigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ name, setName ] = useState('');
    const [ textQuest, setTextQuest ] = useState('');

    const [ questionsList, setQuestionsList ] = useState<any[]>([
        {
            label: 'Пожелание',
            value: 'Пожелание'
        },
        {
            label: 'Жалоба',
            value: 'Жалоба'
        }
    ]);
    const [ questionsValue, setQuestionsValue ] = useState<any>(null);

    const [ faqList, setFaqList ] = useState<any[]>([
        {
            title: 'Пожелание',
            text: 'Пожелание',
            isActive: false
        },
        {
            title: 'Жалоба',
            text: 'Жалоба',
            isActive: false
        }
    ]);

    useEffect(() => init(), []);

    function init() {
        $getQuestionList().then(res => {
            const listUpdate = [...res].map(i => {
                return {
                    ...i,
                    isActive: false
                }
            })

            setFaqList(listUpdate)
        })
    }

    function onClickShowDropdown(item: any, idx: number) {
        const listUpdate = [...faqList].map((i: any, index) => {
            if (idx === index) i.isActive = !i.isActive

            return i
        })

        setFaqList(listUpdate)
    }

    function onClickSave(e: any) {
        e.preventDefault();

        if (!email.length || !name.length || !questionsValue || !textQuest.length) return;

        $createCall(
            {
                email,
                name,
                title: questionsValue?.value,
                text: textQuest
            },
            {
                returnData: false
            }
        ).then(res => {
            if (res.statusCode === 200) successNotify(res.message)
        })
    }

    return (
        <div className='feedback questions list-wrapper'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6 questions-style'>
                        <div className="feedback__logo">
                            <img src="/img/logo/logo-2.png" alt=""/>
                        </div>

                        <div className="feedback__form">
                            <div className="feedback__form-title">
                                <p className={'fgtl'}>Обратная связь</p>
                            </div>

                            <form className="feedback__form-form" onSubmit={onClickSave}>
                                <TextField
                                    type={'email'}
                                    label={ 'Почта' }
                                    value={ email }
                                    className={ '' }
                                    onChangeValue={ setEmail }
                                />

                                <TextField
                                    label={ 'Имя' }
                                    value={ name }
                                    className={ '' }
                                    onChangeValue={ setName }
                                />

                                <FilterSelect
                                    placeholder={'Тема обращения'}
                                    value={questionsValue}
                                    options={questionsList}
                                    className={'mb-3'}
                                    onChange={ setQuestionsValue }
                                />

                                <TextField
                                    label={ 'Текст обращения' }
                                    value={ textQuest }
                                    className={ '' }
                                    onChangeValue={ setTextQuest }
                                />

                                <div className="">
                                    <Button
                                        type={'submit'}
                                        text={ 'Отправить' }
                                        className={ 'btn btn-primary w-100 justify-content-center' }
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="feedback__faq">
                            <div className='feedback__faq-title'>
                                <p className={'fgtl'}>FAQ</p>
                            </div>

                            {!!faqList.length && faqList.map((item: any, idx, row) => (
                                <div
                                    className={ `feedback__faq-items ${item.isActive ? 'active' : ''} questions__items` }
                                    key={ `questions-item-${ idx }` }
                                    onClick={() => onClickShowDropdown(item, idx)}
                                >
                                    <div className='feedback__faq-top questions__items-top'>
                                        <h5 className='feedback__faq-top'>
                                            { item.title }
                                        </h5>

                                        <Arrow/>
                                    </div>

                                    <p className='feedback__faq-desc questions__items-desc'>
                                        { item.text }
                                    </p>
                                </div>
                            )) }

                            {!faqList.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список пуст
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
