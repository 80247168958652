import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import ProtectedRoute from "../components/ProtectedRoute";

import { Main } from "../containers/main";
import { Questions } from "../containers/questions";

const RoutesComponent = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={ <AdminLayout/> }
                >
                    <Route path='/*' element={<Main/>}/>
                    <Route path='/share' element={<Main/>}/>

                    <Route path='support' element={<Questions/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
