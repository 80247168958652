import { FC } from "react";
import Button from "../form/Button";
import { AndroidsFrame1 } from "../../img/icon/AndroidsFrame1";

import { DownloadIcon } from "../../img/icon/DownloadIcon";
import { AndroidsFrame2 } from "../../img/icon/AndroidsFrame2";
import { AndroidsFrame3 } from "../../img/icon/AndroidsFrame3";
import { AndroidsFrame4 } from "../../img/icon/AndroidsFrame4";
import { DocumentIcon } from "../../img/icon/DocumentIcon";
import { HomeIcon } from "../../img/icon/HomeIcon";
import { LaptopIcon } from "../../img/icon/LaptopIcon";

export const Androids = () => {
    const isMobile = window.innerWidth < 1030
    return (
        <>
            <div id='androids' style={ {marginTop: 120} } className={ 'androids' }>
                <h1 className={ 'androids__title' }>Как начать?</h1>

                <div className='androids__first'>
                    { isMobile && (
                        <div className={ 'androids__first-frame' }>
                            <img className={ 'androids__first-img' } src="/img/desktop/androids_1.png" alt="" />
                            <AndroidsFrame1 />
                        </div>
                    ) }

                    <div className={ 'androids__first-title' }>
                        <DownloadIcon />
                        <h1 className={ 'androids__first-title' }>Скачай приложение</h1>
                    </div>

                    { !isMobile && (
                        <>
                            <img className={ 'androids__first-img' } src="/img/desktop/androids_1.png" alt="" />

                            <div className={ 'androids__first-frame' }>
                                <AndroidsFrame1 />
                            </div>
                        </>
                    ) }

                    <p className={ 'androids__first-desc' }>
                        Первый шаг в виртуальное приключение начинается с загрузки нашего приложения. Найди его в
                        магазине (AppStore, GooglePlay) , чтобы получить доступ к миру невероятных возможностей.
                    </p>
                </div>

                <div className='androids__second'>
                    { isMobile && (
                        <div className={ 'androids__second-frame' }>
                            <img className={ 'androids__second-img' } src="/img/desktop/androids_2.png" alt="" />
                            <AndroidsFrame2 />
                        </div>
                    ) }

                    <div className={ 'androids__second-title' }>
                        <DocumentIcon />
                        <h1 className={ 'androids__second-title' }>Пройди регистрацию</h1>
                    </div>

                    { !isMobile && (
                        <>
                            <img className={ 'androids__second-img' } src="/img/desktop/androids_2.png" alt="" />

                            <div className={ 'androids__second-frame' }>
                                <AndroidsFrame2 />
                            </div>

                        </>
                    ) }

                    <p className={ 'androids__second-desc' }>
                        Стань частью нашего фиджитал сообщества, пройди регистрацию в "Мой Фиджитал". Это твой ключ к
                        эксклюзивным событиям, турнирам и привилегиям.
                    </p>
                </div>

                <div className='androids__first'>
                    { isMobile && (
                        <div className={ 'androids__first-frame' }>
                            <img className={ 'androids__first-img' } src="/img/desktop/androids_3.png" alt="" />
                            <AndroidsFrame3 />
                        </div>
                    ) }

                    <div className={ 'androids__first-title' }>
                        <LaptopIcon />
                        <h1 className={ 'androids__first-title' }>Забронируй место</h1>
                    </div>

                    { !isMobile && (
                        <>
                            <img className={ 'androids__first-img' } src="/img/desktop/androids_3.png" alt="" />

                            <div className={ 'androids__first-frame' }>
                                <AndroidsFrame3 />
                            </div>
                        </>
                    ) }

                    <p className={ 'androids__first-desc' }>
                        Бронируй и оплачивай пространство для занятий через приложение. Гарантированно получай доступ к
                        передовому оборудованию и уютной атмосфере, созданной специально для тебя
                    </p>
                </div>

                <div className='androids__second'>
                    { isMobile && (
                        <div className={ 'androids__second-frame' }>
                            <img className={ 'androids__second-img' } src="/img/desktop/androids_4.png" alt="" />
                            <AndroidsFrame4 />
                        </div>
                    ) }

                    <div className={ 'androids__second-title' }>
                        <HomeIcon />
                        <h1 className={ 'androids__second-title' }>Посети центр</h1>
                    </div>

                    { !isMobile && (
                        <>
                            <img className={ 'androids__second-img' } src="/img/desktop/androids_4.png" alt="" />

                            <div className={ 'androids__second-frame' }>
                                <AndroidsFrame4 />
                            </div>
                        </>
                    ) }

                    <p className={ 'androids__second-desc' }>
                        Пришло время окунуться в мир фиджитал! Посети Фиджитал-центр и
                        насладись игрой на высококлассных компьютерах, участвуй в турнирах, общайся с единомышленниками
                        и делись своими эмоциями. У нас рождаются легенды!
                    </p>
                </div>

            </div>

            <div className='androids-second' id='ios'>
                <h1 className={ 'androids__title' }>возможности </h1>
                { window.innerWidth < 930 ? (
                    <img
                        className={ 'androids-mobile-2' }
                        src="/img/androids-mobile-2.jpg"
                        alt="img"
                    />
                ) : (
                    <img
                        className={ 'androids__image-bottom' }
                        src="/img/android_2.jpg"
                        alt="img"
                    />
                ) }

                <h1 className={ 'androids-second-title' }>Цифрового профиля <span>Мой Фиджитал</span></h1>
            </div>
        </>
    )
}
