import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import { FormProps } from "./interface";

export const $getQuestionList = (handler?: IApiHandlerParams): Promise<any> => api
    .get('/support', {
        handler
    })

export const $createCall = (data: FormProps, handler?: IApiHandlerParams): Promise<any> => api
    .post('/support/feedback', data, {
        handler
    })
