export const HomeIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.75 43.75V29.4851C18.75 28.172 19.8693 27.1076 21.25 27.1076H28.75C30.1307 27.1076 31.25 28.172 31.25 29.4851V43.75M23.5512 6.68994L7.30119 17.6792C6.6417 18.1252 6.25 18.8472 6.25 19.6168V40.1838C6.25 42.1533 7.92893 43.75 10 43.75H40C42.0711 43.75 43.75 42.1533 43.75 40.1838V19.6168C43.75 18.8472 43.3583 18.1252 42.6988 17.6792L26.4488 6.68994C25.5814 6.10335 24.4186 6.10335 23.5512 6.68994Z" stroke="url(#paint0_linear_6597_22123)" stroke-width="3" stroke-linecap="round"/>
            <defs>
                <linearGradient id="paint0_linear_6597_22123" x1="63.5" y1="-18.5" x2="-30.2936" y2="2.18383" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0AFE0B"/>
                    <stop offset="1" stop-color="#FFF624"/>
                </linearGradient>
            </defs>
        </svg>
    )
}