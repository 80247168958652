import { FC } from "react";
import Button from "../form/Button";

export const Project = () => {
    return (
        <div className='project' id='about_us'>
            <img
                className='project__img'
                src="/img/project_left.png"
                alt=""
            />

            <div className='project__content'>
                <img
                    className={ 'project__title' }
                    src="/img/project_title.png"
                    alt="#"
                />

                <p className='desc project__content-desc'>Мы предлагаем новое уникальное решение, синергию цифровых и классических видов спорта - Фиджитал-центр, место для увлекательных занятий классическими и киберспортивными дисциплинами.
                    Благодаря нашей платформе “Мой Фиджитал” занятия в центре становятся комфортнее и интереснее. Платформа позволяет записаться и оплатить занятия, получать советы и задания по выбранным дисциплинам и анализировать свои достижения.
                </p>
            </div>

            <img className='project__img'
                 src="/img/project_right.png"
                 alt=""
            />
        </div>
    )
}
