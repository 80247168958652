export default function Arrow({ color="white", size = 20 }) {
  return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.5">
				<path d="M5 7.5L10 12.5L15 7.5"
					  stroke={color}
					  strokeWidth="1.5"
					  strokeLinecap="round"
					  strokeLinejoin="round"
				/>
			</g>
		</svg>
  );
}
