export const AndroidsFrame3 = () => {
  const isMobile = window.innerWidth < 1030
  return (
    <svg width="1108" height="216" viewBox={`0 0 ${isMobile ? 475 : 1108} 216`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_7181_20551" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M471 5H1103V119.124L1061.51 153H471V5ZM1063.29 158L1108 121.497V0H466V158H1063.29Z"/>
      </mask>
      {!isMobile && (<path d="M1103 5V0H1108V5H1103ZM471 5H466V0H471V5ZM1103 119.124H1108V121.497L1106.16 122.997L1103 119.124ZM1061.51 153L1064.67 156.873L1063.29 158H1061.51V153ZM471 153V158H466V153H471ZM1063.29 158L1066.45 161.873L1065.07 163H1063.29V158ZM1108 121.497H1113V123.869L1111.16 125.37L1108 121.497ZM1108 0V-5H1113V0H1108ZM466 0H461V-5H466V0ZM466 158V163H461V158H466ZM1103 10H471V0H1103V10ZM1098 119.124V5H1108V119.124H1098ZM1058.35 149.127L1099.84 115.251L1106.16 122.997L1064.67 156.873L1058.35 149.127ZM471 148H1061.51V158H471V148ZM476 5V153H466V5H476ZM1060.13 154.127L1104.84 117.624L1111.16 125.37L1066.45 161.873L1060.13 154.127ZM1103 121.497V0H1113V121.497H1103ZM1108 5H466V-5H1108V5ZM471 0V158H461V0H471ZM466 153H1063.29V163H466V153Z" fill="url(#paint0_linear_7181_20551)" mask="url(#path-1-inside-1_7181_20551)"/>)}
      <path d="M412 213.5H3V67.5L60.5 2.5H468.5V157L412 213.5Z" stroke="url(#paint1_linear_7181_20551)" stroke-width="5"/>
      <defs>
        <linearGradient id="paint0_linear_7181_20551" x1="466" y1="-6.3649" x2="906.073" y2="389.331" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DF0275"/>
          <stop offset="1" stop-color="#FF6B00"/>
        </linearGradient>
        <linearGradient id="paint1_linear_7181_20551" x1="468.5" y1="-5.99996" x2="2.49998" y2="221.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DF0275"/>
          <stop offset="1" stop-color="#FF6B00"/>
        </linearGradient>
      </defs>
    </svg>
  )
}