type InputObject = {
    [key: string]: any; // Используйте 'any' для значений любого типа
};

export default class TransformObjArray {
    getValue = (value: any): string => {
        if (typeof value === 'object' && !(value instanceof Date)) return value.label;

        return value || ''
    };

    getRequestObj = (fieldList: any[]) => {
        return fieldList.reduce((acc, field) => {
            if (field.key && field.hasOwnProperty('value')) {
                if (typeof field.value === 'boolean' || field.checked || field.checkbox) acc[field.key] = field.value || false;
                else acc[field.key] = field.value ? this.getValue(field.value) : '';
            }

            return acc;
        }, {});
    };

    filterEmptyStringsAndFalsyValues(input: InputObject): InputObject {
        const result: InputObject = {};

        for (const key in input) {
            const value = input[key];

            if (value !== "" && value !== undefined && value !== null) {
                result[key] = value;
            }
        }

        return result;
    };

    formValidateByRequired = (requestObj: any, list: any[], isRequiredImage: boolean = true): any => {
        let isValid = undefined;

        Object.keys(requestObj).forEach(i => {
            list.forEach(item => {
                if ((item.key === i) && (item.required)) {
                    if (!item.value
                        && !item.checkbox
                        && item.type !== 'number'
                    ) isValid = item;

                    if (item.minLength && (item.value.length < item.minLength)) isValid = item;
                    if (item.maxLength && (item.value.length > item.maxLength)) isValid = item;
                }
            })

            if ((i === 'image') && (requestObj.image === null) && isRequiredImage) isValid = true;
        });

        return isValid;
    }
}
