import { Header } from "../header/Header";
import Button from "../form/Button";

export const Introduction = () => {
    return (
        <div id={'main'} className='introduction'>
            <img className='background' src="/img/cover_main_1.jpg" />
            <Header />

            <div className='introduction__content'>
                <div className='introduction__content-left'>
                    <h1 className='introduction__title'>Открой мир <br /> фиджитал спорта</h1>

                    <p className='introduction__desc'>
                        Занимайся в инновационном гармоничном формате, стирая границы между виртуальным и реальным
                        развивайся по-новому благодаря нашей платформе
                    </p>
                </div>

                <div className='introduction__socials'>
                    <img src="/icons/icon_telegram.png" alt="" />
                    <img src="/icons/icon_instagram.png" alt="" />
                    <img src="/icons/icon_facebook.png" alt="" />
                    <img src="/icons/icon_messanger.png" alt="" />
                </div>
            </div>
        </div>
    )
}
