import { IApiHandlerParams, IRequestConfig, TApiResponse } from "./interface";
import { API_DEF_CONFIG, API_URL } from "./config";
import {
    autoLogoutApiStatusCodes,
    refreshTokenApiStatusCodes,
    skipApiStatusCodes, staffCenterNotCodes,
    successApiStatusCodes,
    successRequestStatusCodes
} from "./status-code";
import { failureNotify } from "../notifications";
import {
    decreaseLoadingCountAction,
    increaseLoadingCountAction,
    selectLoading
} from "../redux/reducers/loading/reducer";
import { store } from "../redux/store";
import Cookies from "js-cookie";

export const loadingIsActive = (): boolean =>
    selectLoading(store.getState() as any);

export const setLoading = (isActive: boolean) =>
    store.dispatch(isActive ? store.dispatch(increaseLoadingCountAction()) : store.dispatch(decreaseLoadingCountAction()));

export const activateLoading = (_config?: IApiHandlerParams) =>
    (!_config || _config.disableLoader !== true) && setLoading(true);

export const hideLoading = (_config?: IApiHandlerParams) =>
    (_config ? _config.disableLoader !== true : true) && loadingIsActive() && setLoading(false);

export const addToast = (msg: string, type: string, timeout?: number) => {
    failureNotify(msg, timeout)
    // (<any>useAlertMixin())[type](msg, false);
}

export const isSuccessApiStatusCode = (statusCode: number): boolean =>
    successApiStatusCodes.includes(statusCode);

export const isSkipApiStatusCode = (statusCode: number): boolean =>
    skipApiStatusCodes.includes(statusCode);

export const isSuccessResponseStatusCode = (statusCode: number): boolean =>
    (isSuccessApiStatusCode(statusCode) || isSkipApiStatusCode(statusCode));

export function getFullConfig(config?: IApiHandlerParams): IApiHandlerParams {
    return Object.assign({}, API_DEF_CONFIG, config || {});
}

export function isSuccessResponse(r: TApiResponse): boolean {
    try {
        return (successRequestStatusCodes.includes(r.statusCode));
    } catch (err) {
        console.warn('isSuccessResponse()', err);

        return false;
    }
}


export const getRequestUrl = (config?: IRequestConfig): string => {
    return config?.handler?.dontUseApiUrl ? "" : API_URL;
}


export const handleErrorResponse = (
    _config?: IApiHandlerParams,
    r: TApiResponse = null
): TApiResponse => {
    if (!r || (r.data && r.data.statusCode === 200)) return;

    try {
        if (autoLogoutApiStatusCodes.includes(r?.response?.status as number)) {
            // store.commit('auth/setAuthState');
        } else if (refreshTokenApiStatusCodes.includes(r?.response?.status as number)) {
            // $authRefresh();
        } else if (!_config || !_config.dontShowErrorMessage) {
            addToast(r?.response?.data?.message || r?.data?.message, 'error');
        }

    } catch (err) {
        console.warn('handleErrorResponse()', err);
    }

    hideLoading(_config);

    const mainPage = window.location.origin + '/'

    if (staffCenterNotCodes.includes(r?.response?.status)) {
        window.location.href = window.location.origin + '/non/game'
    }

    if (r?.response?.status === 401) {
        console.log(r, 401)
        // TODO: auto-logout

        window.location.href = mainPage
    }

    return r;
};

export async function requestHandler(r: TApiResponse, _config?: IApiHandlerParams): Promise<TApiResponse> {
    hideLoading(_config);

    if (!isSuccessResponse(r)) handleErrorResponse(_config, r);

    try {
        return (_config ? _config.returnData : true) ? r.data.result : r.data;
    } catch (err) {
        console.warn('requestHandler()', err);

        return null;
    }
}
