export const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path d="M18.75 35.25H31.25M25 12.3333V26.9167M25 26.9167L32.2916 19.625M25 26.9167L17.7083 19.625M25 45.6667C36.5062 45.6667 45.8333 36.3396 45.8333 24.8333C45.8333 13.3271 36.5062 4 25 4C13.4937 4 4.16666 13.3271 4.16666 24.8333C4.16666 36.3396 13.4937 45.6667 25 45.6667Z" stroke="url(#paint0_linear_3122_17636)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <defs>
        <linearGradient id="paint0_linear_3122_17636" x1="58.9571" y1="-4.3863" x2="-4.21964" y2="50.9633" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFF624"/>
          <stop offset="1" stop-color="#05FE0A"/>
        </linearGradient>
      </defs>
    </svg>
  )
}