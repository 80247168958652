import { FC } from "react";

export const Games = () => {
    return (
        <div className='games' id={ 'games' }>
            <h1 className='games__title'>наше <span>преимущество</span></h1>

            <div className='games__first'>
                <div className='games__first-background'>
                    <div className='games__first-item'>
                        <div className={ 'games__first-item-text' }>
                            <h1>Современное <span>оборудование</span></h1>

                            <p>В наших центрах мы предоставляем доступ к высокопроизводительному и современному игровому
                                оборудованию. Мощные компьютеры и последние игровые консоли ждут вас!</p>
                        </div>
                        <img src="/img/desktop/games_1.png" alt="" />
                    </div>
                </div>
            </div>

            <div className='games__second'>
                <div className='games__second-background'>
                    <div className='games__second-item'>
                        <img src="/img/desktop/games_2.png" alt="" />

                        <div className={ 'games__second-item-text' }>
                            <h1> Турниры и <span>соревнования</span></h1>

                            <p>Прими вызов, соревнуйся в сезонных рейтинговых турнирах и других соревнованиях центров,
                                попади в Топ-игроков</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='games__third'>
                <div className='games__third-background'>
                    <div className='games__third-item'>
                        <div className={ 'games__third-item-text' }>
                            <h1>Широкий <br /><span>выбор игр</span></h1>

                            <p>Независимо от того, являешься ли ты фанатом стратегий в реальном времени, любителем
                                боевиков или предпочитаешь увлекательные приключения, в нашем центре ты найдешь полный
                                спектр игр на любой вкус.</p>
                        </div>
                        <img src="/img/desktop/games_3.png" alt="" />
                    </div>
                </div>
            </div>

            <div className='games__fourth'>
                <div className='games__fourth-background'>
                    <div className='games__fourth-item'>
                        <img src="/img/desktop/games_4.png" alt="" />

                        <div className={ 'games__fourth-item-text' }>
                            <h1> Система  <span>геймификации</span></h1>

                            <p>Эксклюзивная система наград и бонусов для того чтобы сделать посещение особенным событием
                                и отметить каждое твое достижение – мы ценим каждого пользователя "Мой Фиджитал".</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*<img*/ }
            {/*    className={'games__image'}*/ }
            {/*    src="/img/games_1.png" alt="" />*/ }

            {/*<img*/ }
            {/*    className={'games__image-bottom'}*/ }
            {/*    src="/img/games_2.jpg" alt="" />*/ }
        </div>
    )
}
