import { FC, useEffect, useRef } from "react";
import { Introduction } from "../../components/introduction/Introduction";
import { Project } from "../../components/project/Project";
import { Games } from "../../components/games/Games";
import { Androids } from "../../components/androids/Androids";
import { Forms } from "../../components/forms/Forms";
import { ProjectMobile } from "../../components/project/ProjectMobile";
import { GamesMobile } from "../../components/games/GamesMobile";
import { AndroidsMobile } from "../../components/androids/AndroidsMobile";
import { IntroductionMobile } from "../../components/introduction/IntroductionMobile";

export const Main: FC = () => {
    const windowWidth: any = useRef(window.innerWidth);

    useEffect(() => {
        redirectToApp();
    }, []);

    function redirectToApp() {
        const searchParams = new URLSearchParams(window.location.search);

        const bookingId = searchParams.get('bookingId')
        const ownerId = searchParams.get('ownerId')

        const newsId = searchParams.get('newsId')

        const username = searchParams.get('username')

        let appUrl = 'phygital://'

        if (username) {
            appUrl += `phygitalCard?username=${username}`
        } else if (newsId) {
            appUrl += `newsSingle?id=${newsId}`
        } else if (bookingId || ownerId) {
            appUrl += `share?bookingId=${bookingId}&ownerId=${ownerId}`
        }

        if(appUrl !== 'phygital://') {
            if (navigator.userAgent.match(/Android/i))
                document.location = appUrl;
            else
                window.location.replace(appUrl);
        }
    }

    return (
        <div className="App">
            { windowWidth.current <= 930 ?
                (
                    <>
                        <IntroductionMobile />

                        <ProjectMobile />

                        <Games />

                        <Androids />

                        {/*<AndroidsMobile />*/}

                        <Forms />

                        <div className={'footer__mobile'}>
                            <p className={'footer__text'}>© 2024 Мой Фиджитал</p>
                        </div>
                    </>
                ) :
                (
                    <>
                        <Introduction />

                        <Project />

                        <Games />

                        <Androids />

                        <Forms />

                        <div className={'footer'}>
                            <p className={'footer__text'}>© 2024 Мой Фиджитал</p>

                            <div className={'footer__right'}>
                                <a href={'https://myphygital.ru/docs/public_offer.pdf'} target={'_blank'} className={'footer__text'}>
                                    Публичная оферта
                                </a>
                                <span className={'footer__dot'}></span>
                                <a href={'https://myphygital.ru/docs/user_agreement.pdf'} target={'_blank'} className={'footer__text'}>Пользовательское соглашение</a>
                            </div>
                        </div>
                    </>
                ) }
        </div>
    );
}
