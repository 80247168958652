export const LaptopIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.5 32.5V12.9808C42.5 10.6442 40.5412 8.75 38.125 8.75H11.875C9.45875 8.75 7.5 10.6442 7.5 12.9808V32.5M42.5 32.5C43.8807 32.5 45 33.6193 45 35V38.75C45 40.1307 43.8807 41.25 42.5 41.25H7.5C6.11929 41.25 5 40.1307 5 38.75V35C5 33.6193 6.11929 32.5 7.5 32.5M42.5 32.5H33.75L30 35H20.625L16.25 32.5H7.5" stroke="url(#paint0_linear_6597_22117)" stroke-width="3" stroke-linejoin="round"/>
      <defs>
        <linearGradient id="paint0_linear_6597_22117" x1="65.5" y1="-9" x2="-18.3412" y2="10.1915" gradientUnits="userSpaceOnUse">
          <stop stop-color="#0AFE0B"/>
          <stop offset="1" stop-color="#FFF624"/>
        </linearGradient>
      </defs>
    </svg>

  )
}