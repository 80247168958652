export const AndroidsFrame1 = () => {
  const isMobile = window.innerWidth < 1030
  return (
    <svg width="1108" height="216" viewBox={`0 0 ${isMobile ? 475 : 1108} 216`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {!isMobile && ( <path fill-rule="evenodd" clip-rule="evenodd" d="M471 5H1103V119.124L1061.51 153H471V5ZM1063.29 158L1108 121.497V0H466V158H1063.29Z" fill="url(#paint0_linear_7177_20548)"/>)}
      <path fill-rule="evenodd" clip-rule="evenodd" d="M59.3737 0H471V158.036L413.036 216H0.5V66.5529L59.3737 0ZM61.6263 5L5.5 68.4471V211H410.964L466 155.964V5H61.6263Z" fill="url(#paint1_linear_7177_20548)"/>
      <defs>
        {!isMobile &&  (
          <linearGradient id="paint0_linear_7177_20548" x1="470" y1="115.5" x2="611.669" y2="446.124" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D406D7"/>
            <stop offset="1" stop-color="#0094FF"/>
          </linearGradient>
        )}
        <linearGradient id="paint1_linear_7177_20548" x1="468.5" y1="-5.99994" x2="2.50001" y2="221.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DA02D5"/>
          <stop offset="1" stop-color="#0094FF"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
