import { FC, useState } from "react";
import { Header } from "../header/Header";
import Button from "../form/Button";
import { scrollTo } from "../../utils";

export const IntroductionMobile = () => {
    const [ menuClass, setMenuClass ] = useState('menu hidden')
    const [ isMenuClicked, setIsMenuClicked ] = useState(false)

    const titles = [ 'О нас', 'Преимущества', 'Как начать', 'Возможности', 'Контакты']

    const renderedTitles = titles.map((
        item: any,
        index
    ) => {
        const handleClick = () => {
            setIsMenuClicked(false)
            setMenuClass('menu hidden')

            if (index === 0)
                scrollTo("about_us", 'start')

            if (index === 1)
                scrollTo("games", 'start')

            if (index === 2)
                scrollTo("androids", 'start')
            if (index === 3)
                scrollTo("ios", 'start')

            if (index === 4)
                scrollTo("forms", 'end')

            // if (index === 3)
            //     window.open('https://vsk.onlinedoctor.ru/lpu/')
        }

        return <p onClick={ handleClick } className='img header__titles-title'>{ item }</p>
    })

    const handleDownloadClick = () => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isAndroid = /Android/.test(navigator.userAgent);

        if (isIOS) {
            window.open('https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D1%84%D0%B8%D0%B4%D0%B6%D0%B8%D1%82%D0%B0%D0%BB/id6472727233', '_blank');
        } else if (isAndroid) {
            window.open('https://play.google.com/store/apps/details?id=com.phygital.center', '_blank');
        } else {
            window.open('https://default-download-link.com', '_blank');
        }
    };

    return (
        <div id={'main'} className='introduction'>
            <img className='background' src="/img/cover_main_1.jpg" alt="" />
            <Header
                isMenuClicked={ isMenuClicked }
                setIsMenuClicked={ setIsMenuClicked }
                setMenuClass={ setMenuClass }
            />

            <div className='introduction__content'>
                <div className='introduction__content-left'>
                    <h1 className='introduction__title'>Открой мир <br /> киберспорта</h1>

                    <p  className='introduction__desc'>
                        Открой для себя захватывающий мир киберспорта, где страсть к соревнованиям переплетается с
                        передовыми технологиями нашего инновационного комьюнити.
                    </p>

                    <Button
                        className={ 'header__btn btn btn-main' }
                        onClick={() => handleDownloadClick()}
                        text={ 'Скачать' }
                    />
                </div>
            </div>

            { menuClass === "menu visible" && (
                <div className={ 'menu' }>
                    <div className={ 'menu__content' }>
                        { renderedTitles }
                    </div>

                    <div className={ 'menu__icons' }>
                        <img src="/icons/icon_telegram.png" alt="" />
                        <img src="/icons/icon_instagram.png" alt="" />
                        <img src="/icons/icon_facebook.png" alt="" />
                        <img src="/icons/icon_messanger.png" alt="" />
                    </div>
                </div>
            ) }
        </div>
    )
}
