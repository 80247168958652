import { FC, useState } from "react";
import TextField from "../form/TextField";
import Button from "../form/Button";
import { $createCall } from "../../api/requests/faq";
import { FormProps } from "../../api/requests/faq/interface";
import FilterSelect from "../FilterSelect";
import TransformObjArray from "../../mixins/transform-obj-array";

export const Forms = () => {
    const transformObjArray = new TransformObjArray();

    const [ formFields, setFormFields ] = useState<any>([
        {
            key: 'email',
            type: 'email',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            placeholder: 'Почта',
            imgLeft: '/icons/icon_telegram.png',
            textField: true,
        },
        {
            key: 'name',
            type: 'text',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            placeholder: 'Имя',
            textField: true,
        },
        {
            key: 'text',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            placeholder: 'Тема обращения',
            select: true,
            options: [
                {
                    label: 'Пожелание',
                    value: 0
                },
                {
                    label: 'Жалоба',
                    value: 1
                },
            ]
        },
        {
            key: 'title',
            type: 'text',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            placeholder: 'Текст обращения',
            textField: true,
        },
    ])

    function onChangeSetValue(
        value: string | boolean,
        key: string
    ) {
        const listUpdate = [ ...formFields ].map((i: any) => {
            if (i.key === key) i.value = value;

            return i;
        });

        setFormFields(listUpdate);
    }

    function onClickSave() {
        const requestData = {
            ...transformObjArray.getRequestObj(formFields),
        };

        onCreateSave(requestData);

    }

    function onCreateSave(data: FormProps) {
        $createCall(data)
            .then((res) => {
                if (res && !res.id) return;

                // Reset form fields to empty strings after successful submission
                const emptyFormFields = formFields.map((field: any) => ({
                    ...field,
                    value: '', // Resetting the value to an empty string
                }));

                setFormFields(emptyFormFields); // Update the state to clear the form fields
            })
            .catch((error) => {
                // Handle error if needed
                console.error('Error occurred:', error);
            });

    }

    return (
        <div className='forms' id={ 'forms' }>
            <img className='background' src="/img/cover_main_2.png" alt="" />

            <div className='forms__block'>
                <h1 className='forms__block-title'>
                    Остались <br />
                    <span>вопросы?</span>
                </h1>

                <p className='forms__block-desc'>Оставьте заявку и<br />
                    мы свяжемся с вами
                </p>

                <div className='forms__block-inputs'>
                    { formFields.map((item: any) => (
                        <>
                            { item.textField && (
                                <div className='forms__block-inputs-item'>
                                    <TextField
                                        // imgLeft={item.imgLeft}
                                        placeholder={ item.placeholder }
                                        onChangeValue={ (value) => item.setValue(value, item.key) }
                                        type={ item.type }
                                        value={ item.value }
                                    />
                                </div>
                            ) }

                            { item.select && (
                                <div className='forms__block-inputs-item'>
                                    <FilterSelect
                                        placeholder={ item.placeholder }
                                        value={ item.value }
                                        options={ item.options }
                                        onChange={ (i) => item.setValue(i, item.key) }
                                    />
                                </div>
                            ) }
                        </>

                    )) }
                </div>

                <Button
                    onClick={ onClickSave }
                    className={ 'btn forms-btn btn-main' }
                    text={ 'Отправить' }
                />

                <div style={ {flexDirection: 'row'} } className='forms__socials'>
                    <img src="/icons/icon_telegram.png" />
                    <img src="/icons/icon_instagram.png" />
                    <img src="/icons/icon_facebook.png" />
                    <img src="/icons/icon_messanger.png" />
                </div>
            </div>
        </div>
    )
}
