import { LoadingState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const initialState: LoadingState = {
	loadingCount: 0
};

const loadingReducer = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        increaseLoadingCountAction: (state) => {
            state.loadingCount++;
        },
        decreaseLoadingCountAction: (state) => {
            state.loadingCount--;
        },
    }
});
export const { increaseLoadingCountAction, decreaseLoadingCountAction } = loadingReducer.actions;

export const selectLoading = (state: RootState): boolean => state.loading.loadingCount > 0;

export default loadingReducer.reducer;
